import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs';

import { OAuthService } from '@app/core/o-auth/o-auth.service';

import { FeatureFlagService } from './core/feature-flag.service';

@Component({
  selector: 'om-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(private featureFlagService: FeatureFlagService, private oAuthService: OAuthService) {}

  ngOnInit(): void {
    this.oAuthService.isAuthenticated$.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.featureFlagService.init();
    });
  }
}
