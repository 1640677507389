import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { OAuthService } from '@app/core/o-auth/o-auth.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthGuard implements CanActivate {
  constructor(private oAuthService: OAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.oAuthService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return observableOf(true);
        }
        const { code } = route.queryParams;

        if (code) {
          return this.validateTokenOrLogin(code);
        } else {
          this.oAuthService.login();
          return observableOf(false);
        }
      }),
    );
  }

  validateTokenOrLogin(code) {
    return this.oAuthService.verifyAccessToken(code).pipe(
      take(1),
      map(_ => this.router.parseUrl('/')),
    );
  }
}
