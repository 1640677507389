import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';

import { ClearStorageService } from '@app/core/clear-storage.service';
import { ConfigService } from '@app/core/config.service';
import { CookieStorageService } from '@app/core/cookie-storage.service';
import { ErrorService } from '@app/core/error.service';
import { LocalStorageService } from '@app/core/local-storage.service';
import { LoggerService } from '@app/core/logger.service';
import { WindowService } from '@app/core/window.service';

export interface AccessTokenResponse {
  access_token: string;
  created_at: number;
  scope: string;
  token_type: string;
}

class BlankTokenError extends Error {
  constructor() {
    super('Received a blank OAuth token.');
    this.name = 'BlankTokenError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  static readonly StorageKey = 'token';

  private readonly CODE_VERIFIER_STORAGE_KEY = 'auth:verifier';
  private baseUrl = this.configService.environment.oauth2.providerUrl;
  private oauth2 = this.configService.environment.oauth2;
  private _isAuthenticated$ = new BehaviorSubject(null);
  readonly isAuthenticated$ = this._isAuthenticated$.asObservable().pipe(map(() => !!this.token));

  constructor(
    private cookieStorageService: CookieStorageService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private configService: ConfigService,
    private windowService: WindowService,
    private clearStorageService: ClearStorageService,
    private loggerService: LoggerService,
    private errorService: ErrorService,
  ) {}

  get token() {
    return this.cookieStorageService.getItem(OAuthService.StorageKey);
  }

  login() {
    this.log('Attempting to log in');

    this.clearStorageService.clearAll();
    this.windowService.redirect(this.buildLoginUrl());
  }

  logout() {
    this.log('Logging out');

    const token = this.cookieStorageService.getItem(OAuthService.StorageKey);
    this.clearStorageService.clearAll();

    this.http
      .post(`${this.baseUrl}/oauth/revoke`, { client_id: this.oauth2.clientId, token })
      .pipe(finalize(() => this.windowService.redirect(`${this.baseUrl}/admin/auth/logout`)))
      .subscribe();
  }

  verifyAccessToken(code: string): Observable<string | boolean> {
    this.log('Verifying access token');

    const codeChallenge = this.localStorageService.getItem(this.CODE_VERIFIER_STORAGE_KEY);
    this.localStorageService.removeItem(this.CODE_VERIFIER_STORAGE_KEY);

    const accessTokenRequestParams = {
      code,
      client_id: this.oauth2.clientId,
      grant_type: 'authorization_code',
      redirect_uri: window.location.origin,
      code_verifier: codeChallenge,
    };

    return this.http.post<AccessTokenResponse>(`${this.baseUrl}/oauth/token`, accessTokenRequestParams).pipe(
      take(1),
      map(response => response.access_token),
      tap((token: string) => {
        if (token === '') {
          this.errorService.error(new BlankTokenError());
        }

        this.setAuthToken(token);
      }),
    );
  }

  private log(message: string) {
    this.loggerService.log(message, { context: 'Authentication' });
  }

  // Copied from https://github.com/onemedical/templates-ui/blob/a6741d7ca71bdf4df82794b41f0867b117dae9c8/src/app/core/auth/shared/auth.service.ts#L90-L112
  private generateCodeVerifier(): string {
    const randomBytes = this.windowService.crypto.getRandomValues(new Uint8Array(64));
    const randomString = String.fromCharCode(...randomBytes);

    return this.windowService.binaryToBase64(randomString).replace(/[=+/]/g, char => {
      switch (char) {
        case '=':
          return '';
        case '+':
          return '-';
        case '/':
          return '_';
      }
    });
  }

  private buildLoginUrl() {
    const codeVerifier = this.generateCodeVerifier();
    this.localStorageService.setItem(this.CODE_VERIFIER_STORAGE_KEY, codeVerifier);

    const url = `${this.baseUrl}/oauth/authorize?`;
    const paramsObject = {
      client_id: this.oauth2.clientId,
      redirect_uri: window.location.origin,
      response_type: 'code',
      code_challenge: codeVerifier,
      code_challenge_method: 'plain',
    };
    const params = new HttpParams({ fromObject: paramsObject });

    return url + params.toString();
  }

  private setAuthToken(token: string) {
    this.cookieStorageService.setItem(OAuthService.StorageKey, token);
    this._isAuthenticated$.next(null);
  }
}
